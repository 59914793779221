import React,{useContext} from "react";
import { FaArrowRight } from "react-icons/fa";
import { GlobalStateContext } from "../UseContextComponents/GlobalStateProvider";
import { Link } from "react-router-dom";
function Home() {
 
  const { openRegisterForm } = useContext(GlobalStateContext);

  return (
    <div
      id="home"
      className="section grid lg:gap-0 gap-8 pb-8 pt-20 h-full sm:pt-28 lg:px-16 bg-[#0c2543] lg:flex lg:space-x-10 lg:h-screen w-full "
    >
      <div className="px-4 sm:px-16 lg:px-0 grid gap-10 lg:gap-6 lg:w-[50%] py-12 ">
        <div className="w-max h-8"> 
          <span className="pl-6 pb-3 pt-3 pr-6 text-xl sm:text-2xl bg-line text-white font-redhat font-semibold rounded-l-full rounded-r-full">
            Craft Your Clarified Era..
          </span>
        </div>
        <div>
          <span className="text-white font-bold font-redhat text-2xl sm:text-4xl">
            Feeling confused about
            <br />
            your career path?
          </span>
        </div>
        <div className="text-white font-redhat font-normal text-lg md:text-xl">
          <span>
          EduStein's Bootcamps are here to provide clarity with engaging and immersive content. Discover the right direction, build essential skills, and confidently navigate your future with us.

         <br></br>Join us on this journey to unlock your true potential and achieve your career goals!

          </span>
        </div>
        <div className="p-2 bg-white max-w-96 md:max-w-80 min-w-max w-64 sm:w-72 md:w-96 h-12 md:h-20 rounded-l-full rounded-r-full flex items-center">
            <div className="text-center space-x-6 w-full flex items-center">
              <span className="font-redhat font-semibold md:font-bold text-lg sm:text-2xl text-navItems flex-grow text-center">
                Be an EduStein
              </span>
              <span className="ml-auto bg-line rounded-full h-10 w-10 md:h-14 md:w-14 text-2xl font-semibold grid place-items-center cursor-pointer">
                <p onClick={()=>openRegisterForm()}><FaArrowRight /> </p>
              </span>
            </div>
          </div>
      </div>
      <div className="w-full lg:w-[50%] h-full sm:h-[80%] lg:h-full flex items-center justify-center  md:p-0  ">
       <div className="w-[20rem] sm:w-[40rem] lg:w-full  h-full ">
       <img
          className="object-contain  h-full w-full  "
          src="/images/image5.jpg"
          alt="About Us Image"
        />  
       </div>
      </div>
    </div>
  );
}

export default Home;
