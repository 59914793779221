import React from 'react';
import Carousel from 'react-elastic-carousel';
import { format } from 'date-fns';
import CustomArrow from './CustomArrow';

const BootcampCards = ({ data = [], setDisplayCard, setId }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM dd, yyyy ");
  };
  console.log(data);
  const handleClick = (id) => {
    setDisplayCard(false);
    setId(id);
    console.log(id);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    {width:700,itemsToShow:2,itemsToScroll:2},
    { width: 768, itemsToShow: 2, itemsToScroll: 2 }, // Fixed 2 items per row for mid-size screens
    { width: 1000, itemsToShow: 3, itemsToScroll: 3 } // Fixed 3 items per row for larger screens
  ];

  return (
    <div className="carousel-wrapper  ">
      <Carousel
        breakPoints={breakPoints}
        renderArrow={(props) => <CustomArrow {...props} />}
        pagination={false}
        itemPadding={[0, 0]} 
      >
        {data && data.length > 0 ? (
          data.map((camp, index) => (
            <div key={index} className="carousel-item "> 
              <BootcampCard
                image={camp.image_path}
                date={formatDate(camp.start_date)}
                title={camp.title}
                amount={camp.amount}
                onClick={() => handleClick(index)}
              />
            </div>
          ))
        ) : (
          <p className="text-gray-700">No bootcamps available.</p>
        )}
      </Carousel>
    </div>
  );
};

const BootcampCard = ({ image, date, title,amount, onClick }) => (
  <div
    className=" w-max-sm   cursor-pointer bg-white  p-2 rounded-[30px]  "
    onClick={onClick}
  >
    <img className="w-full  object-cover rounded-t-[30px]" src={image} alt={title} />
    <div className='p-2 font-redhat h-max justify-between flex  '>
     <div>
     <p className='mt-2 font-bold text-lg '>{title}</p>
      <p className='text-sm text-gray-500 mt-2'>{date}</p>
      <p className='text-xl font-bold mt-2'>₹{amount}</p>
     </div>
     <div className='flex items-center'>
  <button className='border-2 border-line text-normal  px-4 py-2 rounded-full transition duration-500 ease-in hover:scale-105'>
    See details
  </button>
</div>

    </div>
   
  </div>
);

export default BootcampCards;
