import React from 'react';
import { FaChevronRight,FaChevronLeft } from "react-icons/fa6";
const CustomArrow = ({ type, onClick, isEdge }) => {
  const pointer = type === 'PREV' ? <FaChevronLeft className='text-4xl font-bold text-white' /> : <FaChevronRight className='text-4xl font-bold text-white'/>;
  return (
    <button
      onClick={onClick}
      disabled={isEdge}
      className={`p-2 m-2 rounded-full hidden sm:block    ${
        isEdge ? 'opacity-50 cursor-not-allowed hidden' : 'opacity-100 cursor-pointer'
      }`}
      aria-label={type === 'PREV' ? 'Previous Slide' : 'Next Slide'}
    >
      {pointer}
    </button>
  );
};

export default CustomArrow;
